import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guide: true,
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    hide: (state) => {
      state.guide = false;
    },
    show: (state) => {
      state.guide = true;
    },
  },
});

export default guideSlice.reducer;
export const { hide, show } = guideSlice.actions;
